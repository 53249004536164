<template>
  <input-text-password
    v-model="password"
    @focus="isErrorPassword = false"
    @blur="validatePassword()"
    @input="$emit('update:isValid', isValid)"
    :caption="isErrorPassword"
    :error="isErrorPassword"
    :success="isValid"
    :required="true"
    :minlength="minLength"
    :label="label"
    :placeholder="placeholder"
  >
    <template #caption>
      <slot name="caption">
        Hasło musi zawierać co najmniej {{ minLength }} znaków!
      </slot>
    </template>
  </input-text-password>
</template>

<script>
import { ref, computed, watch } from "vue";
import InputTextPassword from "@/components/UI/InputTextPassword.vue";

export default {
  components: { InputTextPassword },

  props: {
    modelValue: String,
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },

  emits: ["update:modelValue", "update:isValid"],

  setup(props, { emit }) {
    const password = ref(props.modelValue);
    const minLength = ref(7);

    // Password validation
    const isErrorPassword = ref(false);
    const validatePassword = () => {
      isErrorPassword.value = !isValid.value;
    };
    const isValid = computed(() => {
      if (password.value === null) return false;

      return password.value.length >= minLength.value;
    });

    watch(password, (newPassword) => {
      emit("update:modelValue", newPassword);
    });
    watch(
      () => props.modelValue,
      (newPassword) => {
        password.value = newPassword;
      }
    );

    watch(
      isValid,
      (newIsValid) => {
        emit("update:isValid", newIsValid);
      },
      { immediate: true }
    );

    return {
      password,
      minLength,
      isErrorPassword,
      validatePassword,
      isValid,
    };
  },
};
</script>
