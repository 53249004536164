<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Ustalenie hasła'" :short="true" />
        <InputTextPasswordValidation
          v-model="newPassword.value"
          @update:isValid="setIsValidPassword"
          :label="'Nowe hasło'"
        />
        <ButtonPrimarySave
          @click="handleSave"
          :isDisabled="!newPassword.isValid"
        />
      </div>
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" :isLoginFirst="true" />
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import useIsMobile from "@/composables/useIsMobile";
import usePasswordReset from "@/composables/usePasswordReset";
import {
  ROUTE_PASSWORD_RESET_ERROR_NAME,
  ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
  ROUTE_PASSWORD_RESET_SUCCESS_NAME,
} from "@/router/constants";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import NavMobile from "@/components/NavMobile.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";
import InputTextPasswordValidation from "@/components/UI/InputTextPasswordValidation.vue";

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    NavMobile,
    ButtonPrimarySave,
    InputTextPasswordValidation,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const route = useRoute();
    const router = useRouter();
    const { verifyResetKey, resetPassword, ERROR_CODE } = usePasswordReset();
    const ROUTE_PASSWORD_RESET_FORGOT_PASSWORD = {
      name: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
    };
    const ROUTE_SUCCESS = {
      name: ROUTE_PASSWORD_RESET_SUCCESS_NAME,
    };
    const ROUTE_ERROR_LINK_EXPIRED = {
      name: ROUTE_PASSWORD_RESET_ERROR_NAME,
      query: {
        reason: ERROR_CODE.LINK_EXPIRED,
      },
    };
    const ROUTE_ERROR_WRONG_KEY = {
      name: ROUTE_PASSWORD_RESET_ERROR_NAME,
      query: {
        reason: ERROR_CODE.WRONG_KEY,
      },
    };

    const newPassword = reactive({
      value: null,
      isValid: false,
    });

    const resetKey = computed(() => {
      return route.query.key || null;
    });

    const handleSave = async () => {
      try {
        if (resetKey.value === null) {
          throw new Error("No key found");
        }
        if (newPassword.isValid === false) {
          throw new Error("Pelase set correct password");
        }
        await resetPassword(resetKey.value, newPassword.value);
        router.push(ROUTE_SUCCESS);
      } catch (error) {
        handleError(error);
      }
    };

    const tryToVerifyResetKey = async () => {
      try {
        const { key } = route.query;
        await verifyResetKey(key);
      } catch (error) {
        handleError(error);
      }
    };

    const handleError = (error) => {
      const { graphQLErrors } = error;
      if (!graphQLErrors || graphQLErrors.length === 0) {
        return router.push(ROUTE_ERROR_WRONG_KEY);
      }
      const { code } = graphQLErrors[0].extensions;
      if (code === ERROR_CODE.LINK_EXPIRED) {
        return router.push(ROUTE_ERROR_LINK_EXPIRED);
      }
      if (code === ERROR_CODE.WRONG_KEY) {
        return router.push(ROUTE_ERROR_WRONG_KEY);
      }
      return router.push(ROUTE_ERROR_WRONG_KEY);
    };

    const setIsValidPassword = (newIsValid) => {
      newPassword.isValid = newIsValid;
    };

    onMounted(() => {
      if (resetKey.value === null) {
        // Redirect to Forgot password view, if no reset key parameter found
        return router.push(ROUTE_PASSWORD_RESET_FORGOT_PASSWORD);
      }
      tryToVerifyResetKey();
    });

    return {
      isMobile,
      setIsValidPassword,
      handleSave,
      newPassword,
    };
  },
};
</script>

<style scoped>
.container {
  min-height: calc(100vh - 2 * var(--header-default--height));
  display: flex;
  align-items: center;
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 100px;
}
@media (max-width: 1200px) {
  .container {
    min-height: inherit;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 100px;
  }
}
</style>
