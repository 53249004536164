<template>
  <ButtonPrimary
    :to="to"
    :label="'Zapisz zmiany'"
    :size="size"
    :isDisabled="isDisabled"
  />
</template>

<script>
import ButtonPrimary from "./Base/ButtonPrimary.vue";

export default {
  components: { ButtonPrimary },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
    caption: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
